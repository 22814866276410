import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { css } from 'styled-components';

import { colorsV2 } from '../../../figma/colors-v2';

export const PageSectionStructureStyle = css`
  .icon-wrapper {
    font-size: 28px;
    line-height: 0;
    ${fromScreen(712)} {
      font-size: 38px;
    }
  }
  .header {
    display: grid;
    margin-bottom: 24px;
  }

  .actionGroup {
    white-space: nowrap;
  }

  .action {
    margin-left: 8px;
  }

  .headerItem {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  .titleGroup {
    flex: 1;
    display: flex;
    align-items: center;

    .title {
      &:not(:first-child) {
        margin-left: 8px;
      }

      flex: 1;
    }
  }

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colorsV2.black100};

    &:hover {
      text-decoration: underline;
    }

    &.circle {
      border-radius: 50%;
      background-color: ${colorsV2.gray10};
      padding: 8px;
    }

    span {
      margin-right: 10px;
    }
  }
  .subHeader {
    margin-bottom: 24px;
  }
`;
