import * as React from 'react';

import { DescriptionDynamicContentProps } from './description/types';
import {
  HeaderWithTitleAndIcon,
  HeaderWithTitleAndIconProps,
} from './header/HeaderWithTitleAndIcon';
import { PageSectionLayout } from './PageSectionLayout';
import { DescriptionDynamicContent } from './description/DescriptionDynamicContent';
import { MobileSeeMoreLandingPages } from './Footer/MobileSeeMoreLandingPages';
import { HeaderWithTitleAndIconDolVn } from './header/HeaderWithTitleAndIconDolvn';

export interface PageSectionStructureProps
  extends HeaderWithTitleAndIconProps,
    DescriptionDynamicContentProps {
  className?: string;
  content?: React.ReactElement;

  oldStyling?: boolean;
  isAdmin?: boolean;
  shouldHideSeeMoreText?: boolean;
}

export const PageSectionStructureV2 = ({
  description,
  seeMoreUrl,
  title,
  pageSection,
  icon,
  className,
  content,
  oldStyling = false,
  isAdmin = false,
  shouldHideSeeMoreText = false,
}: PageSectionStructureProps) => {
  return (
    <PageSectionLayout
      className={className}
      header={
        oldStyling ? (
          <HeaderWithTitleAndIconDolVn
            icon={icon}
            title={title}
            pageSection={pageSection}
            seeMoreUrl={seeMoreUrl}
            isAdmin={isAdmin}
            shouldHideSeeMoreText={shouldHideSeeMoreText}
          />
        ) : (
          <HeaderWithTitleAndIcon
            icon={icon}
            title={title}
            pageSection={pageSection}
            seeMoreUrl={seeMoreUrl}
            isAdmin={isAdmin}
          />
        )
      }
      description={
        <DescriptionDynamicContent
          pageSection={pageSection}
          title={title}
          description={description}
        />
      }
      content={content}
      footer={oldStyling ? null : <MobileSeeMoreLandingPages seeMoreUrl={seeMoreUrl} />}
    />
  );
};
