import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { components } from '@doltech/pageManagement/dto';
import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '../../../../figma/colors-v2';

import { Link } from '../../../../figma/Link';

const Main = styled.div`
  cursor: pointer;
  button {
    width: 100%;
    outline: transparent;
    background-color: transparent;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    min-height: 44px;
    color: ${colorsV2.primary100};
    border: 1px solid ${colorsV2.primary100};
    border-radius: 8px;
    margin-top: 34px;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  color: inherit;
  cursor: pointer;
  &:hover {
    color: initial;
  }

  ${fromScreen(776)} {
    display: none;
  }
`;

export interface MobileSeeMoreLandingPagesProps {
  pageSection?: components['schemas']['PageSectionDTO'];
  seeMoreUrl?: string;
}

export const MobileSeeMoreLandingPages = ({ seeMoreUrl }: MobileSeeMoreLandingPagesProps) => {
  if (seeMoreUrl) {
    return (
      <StyledLink className="styled-link" href={seeMoreUrl}>
        <Main className="mobile-see-more-landing-pages">
          <button>Xem tất cả</button>
        </Main>
      </StyledLink>
    );
  }
  return null;
};
