import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { components } from '@doltech/pageManagement/dto';
import { IconSystemV2CourseArrowChevronRightArrowRight } from '@doltech/icons/SystemV2/IconSystemV2CourseArrowChevronRightArrowRight';
import { IconSystemV2CourseArrowArrowRightLine } from '@doltech/icons/SystemV2/IconSystemV2CourseArrowArrowRightLine';

import { Div } from '../../../../figma/renderNode';
import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { Typography } from '../../../../figma/DesignSystemV2/Typography';
import { Link } from '../../../../figma/Link';
import { PageSectionStructureStyle } from '../PageSectionStructure.style';
import { TitleView } from '../View/TitleView/TitleView';

const Main = styled.div`
  ${PageSectionStructureStyle};

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 0;
  align-items: center;

  .responsive-mobile.dol.link-mobile-only {
    display: flex;
    ${fromScreen(768)} {
      display: none;
    }
  }
  .responsive-mobile.dol.link-desktop-only {
    display: none;
    ${fromScreen(768)} {
      display: grid;
      gap: 10px;
      align-items: center;
      grid-auto-columns: max-content;
      grid-auto-flow: column;
    }
  }
`;

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  color: inherit;
  cursor: initial;
  &:hover {
    color: initial;
  }
  &.have-link {
    cursor: pointer;
  }
`;

export interface HeaderWithTitleAndIconDolVnProps {
  icon?: JSX.Element;
  title?: React.ReactNode;
  pageSection?: components['schemas']['PageSectionDTO'];
  seeMoreUrl?: string;
  isAdmin?: boolean;
  shouldHideSeeMoreText?: boolean;
}

export const HeaderWithTitleAndIconDolVn = ({
  icon,
  pageSection,
  title,
  seeMoreUrl,
  isAdmin,
  shouldHideSeeMoreText = false,
}: HeaderWithTitleAndIconDolVnProps) => {
  const Wrapper: any = isAdmin ? Div : StyledLink;
  return (
    <Wrapper className={cl({ 'have-link': seeMoreUrl })} href={seeMoreUrl}>
      <Main className={cl('headerItem', 'titleGroup')}>
        {icon && <div className="icon-wrapper">{icon}</div>}
        {Boolean(title) &&
          (isAdmin || React.isValidElement(title) ? (
            <div className="title">{title}</div>
          ) : (
            <div className="title">
              <TitleView
                title={title as string}
                titleLink={{
                  link: pageSection?.link,
                  linkSettings: pageSection?.linkSettings,
                }}
              />
            </div>
          ))}

        {Boolean(seeMoreUrl && !shouldHideSeeMoreText) && (
          <span className={cl('link', 'action')}>
            <span className={cl('circle', 'link', 'responsive-mobile dol', 'link-mobile-only')}>
              <IconSystemV2CourseArrowArrowRightLine fontSize={20} />
            </span>

            <span className="responsive-mobile dol link-desktop-only">
              <Typography.Text variant="semi-bold/14">Xem thêm</Typography.Text>
              <IconSystemV2CourseArrowChevronRightArrowRight fontSize={16} />
            </span>
          </span>
        )}
      </Main>
    </Wrapper>
  );
};
