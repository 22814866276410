import * as React from 'react';

import { Box } from '../../../../figma/Common/BoxV2';
import { DescriptionView } from '../View/DescriptionView/DescriptionView';
import { DescriptionDynamicContentProps } from './types';

export const DescriptionDynamicContent = ({
  description,
  pageSection,
  title,
}: DescriptionDynamicContentProps) => {
  return (
    <>
      {Boolean(description) && <Box mb="24px">{description}</Box>}
      {Boolean(pageSection?.description) && (
        <DescriptionView
          title={title as string}
          description={pageSection?.description as string}
          mediaInfos={pageSection?.mediaInfo}
        />
      )}
    </>
  );
};
