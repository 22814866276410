import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { IconCourseArrowArrowRight } from '@doltech/icons/IconCourseArrowArrowRight';
import { components } from '@doltech/pageManagement/dto';
import { colorsV2 } from '../../../../figma/colors-v2';
import { ResponsiveTypography } from '../../../../figma/Typography/v3/ResponsiveTypography';
import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';
import { Div } from '../../../../figma/renderNode';
import { Link } from '../../../../figma/Link';
import { PageSectionStructureStyle } from '../PageSectionStructure.style';
import { TitleView } from '../View/TitleView/TitleView';

const Main = styled.div`
  ${PageSectionStructureStyle};

  .responsive-mobile.dol.link-mobile-only {
    display: flex;
    ${fromScreen(776)} {
      display: none;
    }
  }
  .responsive-mobile.dol.link-desktop-only {
    display: none;
    color: ${colorsV2.primary100};
    ${fromScreen(776)} {
      display: grid;
      gap: 10px;
      align-items: center;
      grid-auto-columns: max-content;
      grid-auto-flow: column;
    }
  }
`;

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  color: inherit;
  cursor: initial;
  text-decoration: none;
  &:hover {
    color: initial;
  }

  .header-item {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    align-items: center;
    gap: 8px;
  }
  &.have-link {
    cursor: pointer;
    .header-item {
      grid-template-columns: minmax(0, 1fr) max-content;
    }
  }

  &.have-icon {
    .header-item {
      grid-template-columns: max-content minmax(0, 1fr);
    }
  }

  &.have-link.have-icon {
    .header-item {
      grid-template-columns: max-content minmax(0, 1fr) max-content;
    }
  }
`;

export interface HeaderWithTitleAndIconProps {
  icon?: JSX.Element;
  title?: React.ReactNode;
  pageSection?: components['schemas']['PageSectionDTO'];
  seeMoreUrl?: string;
  isAdmin?: boolean;
}

export const HeaderWithTitleAndIcon = ({
  icon,
  pageSection,
  title,
  seeMoreUrl,
  isAdmin,
}: HeaderWithTitleAndIconProps) => {
  const Wrapper: any = isAdmin ? Div : StyledLink;

  return (
    <Wrapper
      aria-label="header-title-icon"
      className={cl({ 'have-link': seeMoreUrl, 'have-icon': icon })}
      href={seeMoreUrl}
    >
      <Main className="header-item">
        {icon && <div className="icon-wrapper">{icon}</div>}
        {Boolean(title) &&
          (React.isValidElement(title) ? (
            <div className="title">{title}</div>
          ) : (
            <div className="title">
              <TitleView
                title={title as string}
                titleLink={{
                  link: pageSection?.link,
                  linkSettings: pageSection?.linkSettings,
                }}
              />
            </div>
          ))}

        {Boolean(seeMoreUrl) && (
          <span className={cl('action')}>
            <span className="responsive-mobile dol link-desktop-only">
              <ResponsiveTypography.Text variant="semi-bold/14">
                Xem tất cả
              </ResponsiveTypography.Text>
              <IconCourseArrowArrowRight fontSize={16} />
            </span>
          </span>
        )}
      </Main>
    </Wrapper>
  );
};
