import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { PageSectionStructureStyle } from './PageSectionStructure.style';

export interface PageSectionLayoutProps {
  className?: string;
  header?: React.ReactElement;
  subHeader?: React.ReactElement;
  description?: React.ReactElement;
  content?: React.ReactElement;
  footer?: React.ReactElement;
}

const Main = styled.article`
  ${PageSectionStructureStyle};
  display: grid;
  grid-template-columns: minmax(0, 1fr);
`;

export const PageSectionLayout = (props: PageSectionLayoutProps) => {
  const { className, header, subHeader, description, content, footer } = props;

  return (
    <Main className={cl('page-section-layout', className)}>
      {Boolean(header) && <header className="header">{header}</header>}
      {Boolean(subHeader) && <aside className="subHeader">{subHeader}</aside>}
      {Boolean(description) && description}
      {Boolean(content) && content}
      {Boolean(footer) && footer}
    </Main>
  );
};
