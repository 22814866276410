import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" fillRule="evenodd" d="M9.114 17.796a.889.889 0 000 1.208l.27.292c.352.38.953.38 1.304 0l6.196-6.692a.89.89 0 000-1.208l-6.196-6.692a.889.889 0 00-1.304 0l-.27.292a.889.889 0 000 1.208L14.48 12l-5.367 5.796z" clipRule="evenodd" /></svg>;
});
export const IconSystemV2CourseArrowChevronRightArrowRight = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));